<template>
  <div style="background-color: #005677; min-height: 100%">
    <v-container style="margin-top: 70px">
      <v-row justify="center">
        <v-col cols="12" sm="3" style="display: contents">
          <div
            data-aos-duration="1000"
            data-aos="zoom-out-up"
            data-aos-anchor-placement="bottom-bottom"
          >
            <h1
              class="font-weight-bold mb-3 secTitle"
              style="font-size: 60px; width: fit-content; color: white"
            >
              {{title[lang]}}
            </h1>
          </div>
        </v-col>
      </v-row>
      <!-- Latest update -->
      <vue-timeline-update
        v-for="(hist, i) in history"
        :key="i"
        :date="new Date(hist.date)"
        :title="hist.title[lang]"
        :description="hist.description[lang]"
        :thumbnail="origin + hist.photos[0]"
        :category="hist.Headline[lang]"
        icon="circle"
        color="blue"
        theme="dark"
        data-aos-duration="1000"
        data-aos="fade-up"
        :style="{marginBottom: '10px', direction:lang=='ara'?'rtl':'ltr', textAlign:lang=='ara'?'right':'left'}"
      />
    </v-container>
  </div>
</template>
<script>
export default {
  inject: ["historyRepo"],
  computed: {
    history() {
      return this.$store.state.history;
    },
    origin() {
      return this.$store.state.origin;
    },
    lang() {
      return this.$store.state.lang;
    },
    title() {
        return this.$store.state.ourtimeline;
    }
  },
  mounted() {
    this.historyRepo.all().then((response) => {
      this.$store.state.history = response["data"];
    });
    this.$store.commit("setoverlay", { overlay: false });
  },
};
</script>
<style scoped>
.span {
  background: blue;
}
[class^="gb-"]:not(.gb-base-icon) {
  background: #005677;
}
</style>
